import { Component } from "@angular/core";

import { TradersApiService, OtherApiService } from "@services/api.service";

@Component({
  selector: "banks",
  templateUrl: "./banks.component.html",
  styleUrls: ["../body.css"],
})
export class Banks {
  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService
  ) {}


  loadingBanks = true;
  loadingSend = false;
  sendDone = false;

  banks: any = [];
  name: any = ''
  key: any = ''


  ngOnInit() {
    this.getBanks();
  }

  async getBanks() {
    this.loadingBanks = true;
    const data = await this.otherApiService.getAdmin("get-banks");
    this.loadingBanks = false;
    if ((data as any).success) {
      this.banks = (data as any).result;
    }
  }



  async send() {
    const params = {
      name: this.name,
      beautiful_name: this.key,
    };
    this.loadingSend = true;
    const data = await this.otherApiService.post("add-bank", params);
    this.loadingSend = false;
    console.log(data);
    if ((data as any).success) {
      this.name = ''
      this.key = ''
      this.getBanks();
    }
  }

}
