<div class="add-deposit-block">
  <div class="add-deposit">
      <div class="item">
        <label>Имя банка</label>
        <input [(ngModel)]="name" type="text" placeholder="Имя банка"/>
      </div>
    
      <div class="item">
        <label>Key</label>
        <input [(ngModel)]="key" type="text" placeholder="Ключ"/>
      </div>
    
      <button (click)="send()" *ngIf="!loadingSend && !sendDone">
        Добавить банк
      </button>

      <button *ngIf="loadingSend">Loading</button>
   
      
    <button *ngIf="sendDone" style="background: green; color: #fff">
      Успешно
    </button>
  </div>

 
    <table *ngIf="!loadingBanks" class="table" style="width: 100%;">
      <thead>
        <tr>
          <td>#</td>
          <td>Имя</td>
          <td>Ключ</td>
          <td>Amount regex</td>
          <td>Card regex</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of banks">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.beautiful_name }}</td>
          <td>{{ item.amount_regex }}</td>
          <td>{{ item.card_regex }}</td>
        </tr>
      </tbody>
    </table>
 
</div>
