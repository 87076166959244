import { Component, DoCheck, Input } from "@angular/core";

import { TabulatorFull as Tabulator } from "tabulator-tables";
import { FormGroup, FormControl } from "@angular/forms";
import { date } from "@environments/helper";

import { environment } from "@environments/environment";
import { TradersApiService, OtherApiService } from "@services/api.service";

@Component({
  selector: "app-deposit-merchant",
  templateUrl: "./correct-balance-merchant.component.html",
  styleUrls: ["../body.css"],
})
export class CorrectBalanceMerchantComponent {
  constructor(
    private tradersApiService: TradersApiService,
    private otherApiService: OtherApiService
  ) {}

  date = date;
  loadingDeposits = true;
  loadingRate = true;
  loadingMerchants = true;
  loadingSend = false;
  sendDone = false;
  table: any;
  merchants: any = [];
  historys: any = [];
  deposits: any = [];
  amountDeposit = 0;
  selectedMerchantId: any = 0;
  filterTraderId: any = 0;
  amountUndeposit: any = 0;

  ngOnInit() {
    this.getCorrectBalanceMerchant();
    this.getMerchants();
  }

  async getMerchants() {
    this.loadingMerchants = true;
    const data = await this.otherApiService.getAdmin("get-merchants");
    console.log(data)
    this.loadingMerchants = false;
    if ((data as any).success) {
      this.merchants = (data as any).result;
    }
  }

  async getCorrectBalanceMerchant() {
    const data = await this.otherApiService.getAdmin("get-correcnt-balance-merchant");
    if ((data as any).success) {
      this.historys = (data as any).result;
    }
  }

  async sendDeposit() {
    const params = {
      merchant_id: this.merchants[this.selectedMerchantId].id,
      amount_deposit: this.amountDeposit.toFixed(2),
    };

   
    this.loadingSend = true;
    const data = await this.otherApiService.post("set-correcnt-balance-merchant", params);
    this.loadingSend = false;
    console.log(data);
    if ((data as any).success) {
      this.getMerchants();
      this.getCorrectBalanceMerchant();
      this.sendDone = true;
    }
  }
}
