import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { Routes, RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { navbarComponent } from "@header/navbar/navbar.component";
import { ReviewPanelComponent } from "@body/review-panel/review-panel.component";
import { TradersTableComponent } from "@body/traders-table/traders-table.component";
import { InsuranceDepositComponent } from "@body/insurance-deposit/insurance-deposit.component";
import { CardsTableComponent } from "@body/cards-table/cards-table.component";
import { PaymentsListComponent } from "./components/body/payments-list/payments-list.component";
import { DistributionBk } from "@body/distribution-bk/distribution-bk.component";
import { DepositTrx } from "@body/deposit-trx/deposit-trx.component";
import { DistributionProfit } from "@body/distribution-profit/distribution-profit.component";
import { TronHistory } from "@body/tron-history/tron-history.component";
import { Trader } from "@body/trader/trader.component";
import { Rate } from "@body/rate/rate.component";
import { Withdraw } from "@body/withdraw/withdraw.component";
import { Merchants } from "@body/merchants/merchants.component";
import { MerchantEdit } from "@body/merchant-edit/merchant-edit.component";
import { MerchantCreate } from "@body/merchant-create/merchant-create.component";
import { Users } from "@body/users/users.component";
import { DepositMerchantComponent } from "@body/deposit-merchant/deposit-merchant.component";
import { NotificationsComponent } from "@body/notifications/notifications.component";
import { CorrectBalanceMerchantComponent } from "@body/correct-balance-merchant/correct-balance-merchant.component";
import { Banks } from "@body/banks/banks.component";

const appRoutes: Routes = [
  { path: "cards", component: CardsTableComponent },
  { path: "payments", component: PaymentsListComponent },
  { path: "traders", component: TradersTableComponent },
  { path: "insurance-deposit", component: InsuranceDepositComponent },
  { path: "deposit-merchant", component: DepositMerchantComponent },
  { path: "distribution-bk", component: DistributionBk },
  { path: "distribution-profit", component: DistributionProfit },
  { path: "deposit-trx", component: DepositTrx },
  { path: "tron-history", component: TronHistory },
  { path: "trader/:id", component: Trader },
  { path: "rate", component: Rate },
  { path: "withdraw", component: Withdraw },
  { path: "merchants", component: Merchants },
  { path: "users-banned", component: Users },
  { path: "merchants/create", component: MerchantCreate },
  { path: "merchants/:id", component: MerchantEdit },
  { path: "notifications", component: NotificationsComponent},
  { path: "correct-balance-merchant", component: CorrectBalanceMerchantComponent},
  { path: "banks", component: Banks},
  { path: "**", redirectTo: "cards" },
];

@NgModule({
  declarations: [
    AppComponent,
    navbarComponent,
    ReviewPanelComponent,
    TradersTableComponent,
    InsuranceDepositComponent,
    CardsTableComponent,
    PaymentsListComponent,
    DistributionBk,
    DistributionProfit,
    DepositTrx,
    TronHistory,
    Trader,
    Rate,
    Withdraw,
    Merchants,
    MerchantEdit,
    MerchantCreate,
    Users,
    DepositMerchantComponent,
    NotificationsComponent,
    CorrectBalanceMerchantComponent,
    Banks
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [],
  bootstrap: [AppComponent, navbarComponent],
})
export class AppModule {}
