<div class="add-deposit-block" style="margin-top: 50px">
  <h3 style="text-align: left; width: 100%">
    <span style="padding-right: 10px">Заблокированные пользователи</span>
  </h3>

  <div class="block-deposits">
    <div *ngIf="loading">loading...</div>

    <table *ngIf="!loading" class="table">
      <thead>
        <tr>
          <td>Check</td>
          <td>ID</td>
          <td>User ID</td>
          <td>Merchant ID</td>
          <td>Заблокирован до </td>
          <td>Дата блокировки</td>
          <td>Доверие</td>
          <td>Действие</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data; let i = index">
          <td><input type="checkbox" style="width: 100%; height: 100%;" [(ngModel)]="item.checking" (change)="check(item.id, item.checking)"></td>
          <td>{{ item.id }}</td>
          <td>{{ item.user_id }}</td>
          <td>{{ item.merchant_id }}</td>
          <td> <span *ngIf="item.is_banned">{{ item.banned_until }}</span></td>
          <td>{{ item.banned_when }}</td>
          <td>{{ item.trust_rate }}</td>
          <td>
            <div *ngIf="item.loading">loading...</div>
            <button class="btn btn-success btn-sm" *ngIf="!item.loading && item.is_banned" (click)="unBan(i)">
              Разблокировать
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
