import { Component } from "@angular/core";
import { TradersApiService, OtherApiService } from "@services/api.service";

@Component({
  selector: "merchants",
  templateUrl: "./users.component.html",
  styleUrls: ["../body.css"],
})
export class Users {
  constructor(private otherApiService: OtherApiService) {}

  loading = true;
  data: any = [];

  ngOnInit() {
    this.getUsers();
  }

  async check(id:number, checking: boolean) {
    console.log(id, checking)
    const data = await this.otherApiService.post("check-user", {id, checking});
  }
  async getUsers() {
    this.loading = true;
    const data = await this.otherApiService.getAdmin("get-users");
    this.loading = false;
    if ((data as any).success) {
      this.data = (data as any).result;
    }
  }



  async unBan(i: any) {
    this.data[i]["loading"] = true;
    const data = await this.otherApiService.getAdmin("remove-ban-user", {
      id: this.data[i].id,
    });
    this.data[i]["loading"] = false;

    if ((data as any).success) {
      this.data[i]["is_banned"] = false;
    }
  }
}
